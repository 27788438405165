import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,

    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,

    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,

    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },

  {
    exact: true,

    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashdoardHome")),
  },
  {
    exact: true,
    guard: true,
    path: "/graph",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Graph/Graph")),
  },
 
  


  {
    exact: true,
    guard: true,
    path: "/add-price",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/price/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-price",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/price/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/membership-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/MembershipPlan/Membership")),
  },

  {
    exact: true,
    guard: true,
    path: "/wallets-transaction",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallets/Index")),
  },
 
  {
    exact: true,
    guard: true,
    path: "/wallets-balance",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallets/WalletBalance")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallets-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallets/Walletstable")),
  },
  {
    exact: true,
    guard: true,
    path: "/user-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallets/UserWalletDetails")),
  },

 
  {
    exact: true,
    path: "/skills-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/skills/index")),
  },
  {
    exact: true,
    path: "/skills-lists",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Skill/Index.js")),
  },
  {
    exact: true,
    path: "/subskills-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SubSkill/Index.js")),
  },
  {
    exact: true,
    path: "/referral-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/referral/index")),
  },

  {
    exact: true,
    path: "/referral-userlist",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/referral/UserList.js")),
  },
 
 
  {
    exact: true,
    path: "/marketplace",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/PaidCourses/index")),
  },
  {
    exact: true,
    path: "/marketplace-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/MarketplaceList/index")),
  },
  {
    exact: true,
    path: "/drop",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DropCourses/index")),
  },
 
  {
    exact: true,
    path: "/skill-drops-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SkillDropList/index")),
  },
 
  {
    exact: true,
    path: "/user-lists",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/MarketplaceList/MarketplaceTable.js")),
  },
  {
    exact: true,
    path: "/user-skilldrops-lists",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SkillDropList/SkillListTable")),
  },
  
 
  {
    exact: true,
    path: "/query-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/querylist/QueryList")
    ),
  },
  {
    exact: true,
    path: "/query-reply",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/querylist/Index")
    ),
  },


  {
    exact: true,
    path: "/user-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/userlist/UserList")
    ),
  },
  {
    exact: true,
    path: "/view-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/userlist/UserTabs")
    ),
  },
  {
    exact: true,
    path: "/create-badges",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/automation/Badge")),
  },
  {
    exact: true,
    path: "/badges-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/automation/BadgeMain")),
  },
  {
    exact: true,
    path: "/member-ship",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/MembershipPlan/Index.js")),
  },
  {
    exact: true,
    path: "/notifications",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/announcement")),
  },
  {
    exact: true,
    path: "/view-notification",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/announcement/UserListTable.js")),
  },

  {
    exact: true,
    path: "/subscribe-user",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SubscribeUser/index")),
  },
  {
    exact: true,
    path: "/blog",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Blogs/Blogs")),
  },

  // Educator approval request
  {
    exact: true,
    path: "/educator-request",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EducatorRequest/CourseRequest")),
  },

 
  {
    exact: true,
    path: "/static",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Static/Index.js")),
  },
  

  {
    exact: true,
    path: "/view-subscriber",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MembershipPlan/View")
    ),
  },
  {
    exact: true,
    path: "/transaction-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/transaction/TransactionMangement")
    ),
  },

  {
    exact: true,
    path: "/coe-partners",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/coepartners/Index.js")
    ),
  },
  {
    exact: true,
    path: "/coe-request",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CoeRequest/CoeRequest")
    ),
  },









  
 
 
  
  
  {
    exact: true,
    path: "/course-module",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/course module/index")
    ),
  },

  {
    exact: true,
    path: "/instructor-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/instructor/Instructor")
    ),
  },

  {
    exact: true,
    path: "/subadmin-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/subadmin/SubAdmin")
    ),
  },

  {
    exact: true,
    path: "/view-subadmin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/subadmin/ViewParticularSubAdmin")
    ),
  },

  {
    exact: true,
    path: "/tier-plan-subscribers",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MembershipPlan/Membership.js")
    ),
  },


  {
    exact: true,
    path: "/course-category",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/courseCategory/index")
    ),
  },

  {
    exact: true,
    path: "/course-category/subcategory",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/courseCategory/courseSubCategory/index")
    ),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
