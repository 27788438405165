import React, { useState, useRef, useEffect } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  Collapse,
  List,
  ListItem,
  makeStyles,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import StarBorder from "@material-ui/icons/StarBorder";

const useStyles = makeStyles((theme) => ({
  item: {
    "& .MuiCollapse-root": {
      "& .MuiCollapse-wrapperInner": {
        padding: "0",
        "& .MuiListItem-gutters": {
          padding: "0",
          display: "flex",
          flexDirection: "column",
        },
      },
    },
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.primary.main,
    padding: "13px 20px",
    justifyContent: "flex-start",
    textTransform: "none",
    marginBottom: "8px",
    letterSpacing: 0,
    width: "calc(100% - 16px)",
    margin: "7px",
    marginBottom: "5px",
    fontWeight: "400",
    fontSize: "13px",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#615dfa",
      borderRadius: "8px",
      "& $icon": {
        color: "#ffff",
      },
    },
  },
  buttonLeaf: {
    color: "#3e3f5e",
    padding: "13px 20px",
    margin: "7px",
    borderRadius: "8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    marginBottom: "5px",
    opacity: 1,
    fontWeight: "700",
    fontSize: "13px",
    "&:hover": {
      color: "#ffff",
      backgroundColor: "#615dfa",
      boxShadow: "4px 7px 12px 0 rgba(97, 93, 250, 0.2)",
      borderRadius: "8px",
      "& $icon": {
        color: "#ffff",
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: "#3e3f5e",
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: "#ffff",
    backgroundColor: "#615dfa",
    boxShadow: "4px 7px 12px 0 rgba(97, 93, 250, 0.2)",
    borderRadius: "8px",
    "& $icon": {
      color: "#ffff",
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  check,
  subItems,
  ...rest
}) => {
  const classes = useStyles();
  const location = useLocation();
  const itemRef = useRef(null);

  const [open, setOpen] = useState(
    JSON.parse(localStorage.getItem(`${title}-open`)) || openProp
  );

  // Save the open state in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(`${title}-open`, JSON.stringify(open));
  }, [open, title]);

  // Scroll into view if the item is active
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (itemRef.current && location.pathname === href) {
        itemRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [location.pathname, href]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;
  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }
  const style = { paddingLeft };

  if (children || subItems) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        {check === true && (
          <Button className={classes.button} onClick={handleToggle}>
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title} style={{fontWeight:"700"}}>{title}</span>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        )}
        <Collapse in={open}>
          <List component="div" disablePadding>
            {subItems &&
              subItems.map((item, index) => (
                <Button
                  key={index}
                  activeClassName={classes.active}
                  className={classes.button}
                  component={RouterLink}
                  exact
                  to={item?.href}
                >
                  {Icon && <div className={classes.icon} size="20" ></div>}
                  <span className={classes.title} style={{fontWeight:"500"}} >{item?.title}</span>
                  {Info && <Info />}
                </Button>
              ))}
          </List>
        </Collapse>
      </ListItem>
    );
  }

  return (
    <>
      {check === true && (
        <ListItem
          className={clsx(classes.itemLeaf, className)}
          disableGutters
          key={title}
          ref={itemRef}
          {...rest}
        >
          <Button
            activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            component={RouterLink}
            exact
            to={href}
          >
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title} >{title}</span>
            {Info && <Info />}
          </Button>
        </ListItem>
      )}
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
